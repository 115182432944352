.heading__loading {
    height: 30px;
    width: 40%;
    padding: 0;
    margin-bottom: 10px;
    animation: pulse 1s infinite ease-in-out;
}
.sub-heading-1__loading {
    height: 20px;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    animation: pulse 1s infinite ease-in-out;
}
.sub-heading-2__loading {
    height: 10px;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    animation: pulse 1s infinite ease-in-out;
}

.__loading {
    animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}