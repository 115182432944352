@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Medium"),
    url("./../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-SemiBold"),
    url("./../assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Bold"),
    url("./../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Rockwell";
  src: local("Rockwell"),
    url("./../assets/fonts/rockwell/Rockwell.ttf") format("truetype");
  font-weight: normal;
}

// @font-face {
//   font-family: "Rockwell";
//   src: local("Rockwell-Regular"),
//     url("./../assets/fonts/rockwell/RockwellBQ-Regular.ttf") format("truetype");
//   font-weight: 600;
// }

// @font-face {
//   font-family: "Rockwell";
//   src: local("Rockwell-Bold"),
//     url("./../assets/fonts/rockwell/Rockwell_MT_Bold.ttf") format("truetype");
//   font-weight: 700;
// }

// @font-face {
//     font-family: "Rockwell";
//     src: local("Rockwell"),
//       url("./../assets/fonts/rockwell/Rockwell.ttf") format("truetype");
//     font-weight: normal;
//   }
//   @font-face {
//     font-family: "Rockwell-Light";
//     src: local("Rockwell-Light"),
//       url("./../assets/fonts/rockwell/Rockwell.ttf") format("truetype");
//     font-weight: normal;
//   }

//   @font-face {
//     font-family: "Rockwell-Regular";
//     src: local("Rockwell-Regular"),
//       url("./../assets/fonts/rockwell/RockwellBQ-Regular.ttf") format("truetype");
//     font-weight: normal;
//   }

//   // @font-face {
//   //   font-family: "Poppins-Medium";
//   //   src: local("Poppins-Medium"),
//   //     url("./../assets/fonts/rockwell/Poppins-Medium.ttf") format("truetype");
//   //   font-weight: normal;
//   // }

//   @font-face {
//     font-family: "Rockwell-Bold";
//     src: local("Rockwell-Bold"),
//       url("./../assets/fonts/rockwell/RockwellStd-Bold.ttf") format("truetype");
//     font-weight: normal;
//   }
