p {
  margin: 0 !important;
}

.fullHeight {
  height: 100vh;
}

.poppinsFamily {
  font-family: 'Poppins', sans-serif !important;
}

.rockwellFamily {
  font-family: 'Rockwell', sans-serif !important;
}

.cursorPointer {
  cursor: pointer;
}

.form-control:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.darkBg {
  background: #091014 !important;
}

.objecFitCover {
  object-fit: cover;
}

.bgSizeCover {
  background-size: cover;
}

.menuItemH {
  height: 190px;
}

.itemBottomBorderRadius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.itemImageRadius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.zIndexUp {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

/***************** Autocomplete map styles ******************/
.pac-logo::after {
  display: none;
}

/***************** Autocomplete map styles ******************/

.contPad {
  @media screen and (min-width: 1024px) {
    padding: 0 120px !important;
  }
}

.mobileShow {
  @media only screen and (min-width: 1024px) {
    display: none !important;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.desktopShow {
  @media only screen and (max-width: 1024px) {
    display: none !important;
  }

  @media only screen and (min-width: 1024px) {
    display: block;
  }
}

.mobilePd {
  @media only screen and (max-width: 1024px) {
    padding: 0 20px;
  }

  @media only screen and (min-width: 1024px) {
    padding: unset;
  }
}

.closeButtonStyle {
  z-index: 100;
  position: absolute !important;
  padding: 0.4px;
  top: 3.2px;
  right: 3.2px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #000000;
  background-color: white;
}

.pac-container {
  z-index: 10000;
}

.input-lr-pad-15 {
  input {
    padding: 0 15px;
  }
}

#delivery-date-select,
#delivery-time-select {
  font-size: 14px;
}

// fallback loader>>>>>>>>>>>>>>>>>>>>>>>>>>>
.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;

}

.loader-box {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.icon {
  font-size: 5rem;
  transform: scale(1);
  background-color: rgb(255, 255, 255);
  border-radius: 150px;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loaderSize 2.5s infinite ease-in-out, loaderColor 7.5s infinite ease-in-out;
  color: #000000;
  box-shadow: 0 0 15px #fff;

  svg {
    font-size: 60px;
  }
}

@keyframes loaderCircle {}

@keyframes loaderSize {
  0% {
    transform: scale(1) rotate(0);
  }

  16% {
    transform: scale(0) rotate(0);
  }

  50% {
    transform: scale(1) rotate(0);
  }

  80% {
    transform: scale(1) rotate(-360deg);
  }

  100% {
    transform: scale(1) rotate(-360deg);
  }
}

@keyframes loaderColor {
  5% {
    color: #000000;
  }

  10% {
    color: #303030;
  }

  39% {
    color: #303030;
  }

  40% {
    color: #4d4d4d;
  }

  70% {
    color: #4d4d4d;
  }

  80% {
    color: #000000;
  }

  100% {
    color: #000000;
  }
}

.disabled {
  color: grey !important;
}

.disabled-checkbox {
  color: grey !important;
}

#checkout-addressForm .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: unset;
}

#checkout-addressForm .MuiInput-underline:before {
  border-bottom: unset;
}

#checkout-addressForm .MuiInput-underline:after {
  border-bottom: unset;
}