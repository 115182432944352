.poweredCon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  color: #808080;
  .text {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .poweredImg {
    width: 125px;
    margin: 1rem 0;
    @media (max-width: 768px) {
      width: 100px;
    }
  }
}
