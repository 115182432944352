* {
  box-sizing: border-box;
}

html,
body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0px;
  padding: 0px;
}

h1,
h2 {
  margin: 0;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}

.max-height {
  height: 100vh;
}
.scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
// Scroll SCSS
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #091014;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #091014;
}

.makeStyles-accordionExpanded-82 {
  margin: 0 !important;
}

.MuiTypography-root {
  user-select: none;
}
